.c-footer {
	padding: 80px 0 50px;
	color: $font-secondary-color;
	background: url("../img/bg-footer-lower.png") no-repeat center top;
	background-size: cover;
	margin-top: -80px;

	@media (--sm-lte) {
		background-size: auto;
		margin-top: -40px;
		padding: 50px 25px 30px;
		background-image: url("../img/bg-footer-lower-xs.png");
	}

	&__canvas-upper {
		margin-bottom: -10px;
		position: relative;

		&::before {
			content: "";
			display: block;
			background: url("../img/bg-footer-upper-right.png") no-repeat center center;
			width: 481px;
			height: 96px;
			position: absolute;
			right: 0;
			top: -17px;

			@media (--sm-lte) {
				width: 160px;
				height: 39px;
				background-size: 100% auto;
				top: 30px;
			}
		}

		&::after {
			content: "";
			display: block;
			background: url("../img/bg-footer-upper-left.png") no-repeat center center;
			width: 195px;
			height: 89px;
			position: absolute;
			top: -15px;
			left: 50%;
			margin-left: -580px;

			@media (--sm-lte) {
				width: 100px;
				height: 50px;
				background-size: 100% auto;
				top: 20px;
				margin: 0;
				left: 25px;
			}
		}

		canvas {
			width: 100%;
			height: 120px;
			overflow: hidden;
			position: relative;
		}
	}

	&__logo {
		a {
			display: block;
			text-decoration: none;
			text-indent: -9999px;
			background: url("../img/logo.png") no-repeat;
			width: 169px;
			height: 132px;
			background-size: 169px auto;

			@media (--sm-lte) {
				width: 140px;
				height: 110px;
				background-size: 140px auto;
				margin: 0 auto 20px;
			}
		}
	}

	&__body {
		width: 1000px;
		margin: 0 auto;
		position: relative;

		@media (--sm-lte) {
			width: 100%;
		}

		&::after {
			content: "";
			display: block;
			background: url("../img/bg-footer-element.png") no-repeat;
			width: 343px;
			height: 42px;
			background-size: 343px auto;
			position: absolute;
			right: 0;
			top: 80px;

			@media (--sm-lte) {
				width: 158px;
				background-size: 100% auto;
				left: -25px;
				top: 60px;
			}
		}
	}

	&__upper {
		display: flex;
		align-items: flex-end;

		@media (--sm-lte) {
			display: block;
		}
	}

	&__address {
		font-size: 1.6rem;
		font-weight: 300;
		letter-spacing: .05em;
		margin-left: 40px;

		@media (--sm-lte) {
			font-size: 1.3rem;
			margin-left: 0;
			text-align: center;

			a {
				color: $font-primary-color;
				font-weight: bold;
				margin-left: 3px;
			}
		}

		@media (--sm-gt) {
			a {
				pointer-events: none;
				text-decoration: none;
				color: $font-secondary-color;
			}
		}

		span {
			display: block;
		}
	}

	&__copyright {
		display: block;
		font-size: 1.1rem;
		font-family: $font-family-lato;
		color: $navy-color;
		margin-top: 25px;

		@media (--sm-lte) {
			text-align: center;
			font-size: 1rem;
			margin-top: 20px;
		}
	}
}
