.c-hero {
	// ☝ slick.js で自動生成されるDOMを参考にする
	// 📝 http://kenwheeler.github.io/slick/
	// stylelint-disable selector-class-pattern, selector-nested-pattern
	text-align: center;

	&__image {
		height: 100%;
		border-radius: 20px;
		overflow: hidden;
		transform: scale(.85);
		transform-origin: center;
		transition: 700ms;
		height: 0;
		position: relative;
		padding-top: calc(630 / 1060 * 100%);

		@media (--sm-lte) {
			border-radius: 10px;
			height: auto;
			padding-top: 0;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}

		.slick-center & {
			transform: scale(1);
			margin: 0 -6%;

			@media (--sm-lte) {
				margin: 0;
			}
		}

		@media (--sm-lte) {
			width: 100%;
			height: 0;
			padding-top: calc(630 / 1190 * 100%);
		}
	}

	&__element {
		margin: 0;
		outline: 0;
		width: calc(100vw - 300px)!important;
		max-width: 1060px;
		min-width: 900px;

		@media (--sm-lte) {
			width: calc(100vw - 40px)!important;
			margin: 0 -2vw;
			min-width: 0;
		}

		a {
			outline: 0;
			transition: 300ms;
			display: block;

			&:hover {
				opacity: .7;
			}
		}
	}

	.slick-slider {
			position: relative;
			display: block;
			box-sizing: border-box;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-ms-touch-action: pan-y;
			touch-action: pan-y;
			-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
			position: relative;
			overflow: hidden;
			display: block;
			margin: 0;
			padding: 0;

			&:focus {
					outline: none;
			}

			&.dragging {
					cursor: pointer;
					cursor: hand;
			}
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			-o-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
	}

	.slick-track {
			position: relative;
			left: 0;
			top: 0;
			display: block;

			&:before,
			&:after {
					content: "";
					display: table;
			}

			&:after {
					clear: both;
			}

			.slick-loading & {
					visibility: hidden;
			}
	}

	.slick-slide {
			float: left;
			height: 100%;
			min-height: 1px;
			[dir="rtl"] & {
					float: right;
			}
			img {
					display: block;
					width: 100%;

			}
			&.slick-loading img {
					display: none;
			}

			&.dragging img {
					pointer-events: none;
			}

			.slick-initialized & {
					display: block;
			}

			.slick-loading & {
					visibility: hidden;
			}

			.slick-vertical & {
					display: block;
					height: auto;
					border: 1px solid transparent;
			}
	}

	.slick-arrow.slick-hidden {
			display: none;
	}

	$slick-font-path: "./fonts/" !default;
	$slick-font-family: "slick" !default;
	$slick-loader-path: "./" !default;
	$slick-arrow-color: white !default;
	$slick-dot-color: black !default;
	$slick-dot-color-active: $slick-dot-color !default;
	$slick-prev-character: "\2190" !default;
	$slick-next-character: "\2192" !default;
	$slick-dot-character: "\2022" !default;
	$slick-dot-size: 6px !default;
	$slick-opacity-default: 0.75 !default;
	$slick-opacity-on-hover: 1 !default;
	$slick-opacity-not-active: 0.25 !default;

	/* Arrows */

	.slick-prev,
	.slick-next {
			position: absolute;
			display: block;
			height: 72px;
			width: 72px;
			line-height: 0px;
			font-size: 0px;
			cursor: pointer;
			background: transparent;
			color: transparent;
			top: 50%;
			border: 3px solid #fff;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
			padding: 0;
			outline: none;
			z-index: 99;
			border-radius: 50%;
			transition: 300ms transform;

			@media (--sm-lte) {
				width: 38px;
				height: 38px;
				border-width: 2px;
			}

			&:hover, &:focus {
					outline: none;
					color: transparent;
					&:before {
							opacity: $slick-opacity-on-hover;
					}
			}
			&.slick-disabled:before {
					opacity: $slick-opacity-not-active;
			}
			&:before {
					font-family: $slick-font-family;
					font-size: 20px;
					line-height: 1;
					color: $slick-arrow-color;
					opacity: $slick-opacity-default;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
			}
	}

	.slick-prev {
		left: 50%;
		background: url("../img/icon-slider-left.svg") no-repeat center center;
		background-size: 39px auto;
		margin-left: calc(-640px);
		background-color: $yello-color02;

		@media(--slider) {
			margin-left: 0;
			left: 4%;
		}

		@media(--slider-s) {
			margin-left: 0;
			left: 1%;
		}

		&:hover {
			@media (--sm-gt) {
				transform: translate(-5px, -50%);
			}
		}

		@media (--sm-lte) {
			background-size: 19px auto;
			left: 2px;
			margin-left: 0;
		}
	}

	.slick-next {
		right: 50%;
		background: url("../img/icon-slider-right.svg") no-repeat center center;
		background-size: 39px auto;
		margin-right: -640px;
		background-color: $primary-color;

		&:hover {
			@media (--sm-gt) {
				transform: translate(5px, -50%);
			}
		}


		@media(--slider) {
			margin-right: 0;
			right: 4%;
		}

		@media(--slider-s) {
			margin-right: 0;
			right: 1%;
		}


		@media (--sm-lte) {
			background-size: 19px auto;
			right: 2px;
			margin-right: 0;
		}
	}
	// stylelint-enable

}
