.c-nav-breadcrumb {
	font-size: 1.4rem;
	width: 1000px;
	margin: 0 auto;
	font-family: $font-family-lato;
	font-weight: 500;

	@media (--md-lte) {
		display: none;
	}

	ol {
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: flex-start;

		> li {
			list-style: none;
			font-size: calc(14 / 16 * 1em);

			&::after {
				content: "";
				display: inline-block;
				margin: 0 15px 0;
				background-color: $secondary-color;
				height: 15px;
				width: 1px;
				transform: rotate(40deg);
				transform-origin: center;
				vertical-align: middle;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	a {
		color: $secondary-color;
		display: inline-block;
		text-decoration: none;
		font-weight: bold;

		span {
			border-bottom: 1px dotted $secondary-color;
		}
	}

	&__item {
		display: inline-block;
		margin: 0;
		padding: 0;
	}

	&__item[data-breadcrumb="current"] {
		a {
			text-decoration: none;
		}
	}
}
