.c-title-page {
	font-size: 1.6rem;
	background: url("../img/bg-title-page.jpg") no-repeat center top;
	background-size: cover;
	margin: 0 0 30px;

	@media (--sm-lte) {
		background-image: url("../img/bg-title-page-xs.jpg");
	}

	h1 {
		text-align: left;
		border: 1px solid transparent;
		border-radius: 5px;
		width: 1000px;
		margin: 0 auto;
		padding: 65px 0 65px 25px;
		font-size: 3.8rem;
		color: $font-primary-color;

		@media (--sm-lte) {
			padding: 30px 25px;
			width: 100%;
			font-size: 2.5rem;
		}
	}

	span {
		background-image: url("../img/icon-heading-other.svg");
		background-size: 81px 81px;
		background-repeat: no-repeat;
		background-position: left center;
		padding: 15px 0 15px 111px;

		@media (--sm-lte) {
			background-size: 48px 48px;
			padding: 15px 0 15px 65px;
		}
	}

	&__about {
		span {
			background-image: url("../img/icon-heading-about.svg");
		}
	}

	&__news {
		span {
			background-image: url("../img/icon-heading-news.svg");
		}
	}

	&__care {
		span {
			background-image: url("../img/icon-heading-care.svg");
		}
	}

	&__information {
		span {
			background-image: url("../img/icon-heading-information.svg");
		}
	}

	&__blog {
		span {
			background-image: url("../img/icon-heading-blog.svg");
		}
	}
}
