a {
	&[href^="tel:"] {
		display: inline-block;

		@media (--sm-gt) {
			text-decoration: none;
			pointer-events: none;
		}
	}
}
