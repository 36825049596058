.c-content-index {
	padding-bottom: 60px;

	&__body {
		@mixin flexible-width-body;
		margin: 0 auto;
	}

	&__card-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 -14px -40px;

		@media (--sm-lte) {
			display: block;
			margin: 0;
		}
	}

	&__card-list-news {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 0 -17px -40px;

		@media (--sm-lte) {
			display: block;
			margin: 0;
		}
	}

	&__card-item {

	}

	&__card-item-blog {
		width: calc(50% - 28px);
		margin: 0 14px 35px;

		@media (--sm-lte) {
			width: 100%;
			margin: 0 0 15px;
		}
	}

	&__card-item-news {
		width: calc(100% / 3 - 34px);
		margin: 0 17px 40px;

		@media (--sm-lte) {
			width: 100%;
			margin: 0 0 15px;
		}
	}
}
