.c-pagination {
	clear: both;
	display: flex;
	font-size: 1.8rem;
	justify-content: center;
	line-height: 4rem;
	margin: 60px 0 0;
	text-align: center;
	align-items: center;

	@media (--sm-lte) {
		margin-top: 20px;
	}

	a {
		background: none;
		color: currentColor;
		text-decoration: none;
		font-size: calc(16 / 18 * 1em);
	}

	&__prev,
	&__next {
		a {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			font-family: $font-family-lato;
			font-weight: 600;
			font-size: 1.8rem;
			line-height: 1;

			@media (--sm-lte) {
				font-size: 1.4rem;
			}

			&[data-disabled="true"] {
				opacity: .3;
				pointer-events: none;
			}
		}
	}

	&__prev {
		order: 1;

		a::before {
			content: "";
			background-image: url("../img/icon-ar-left-brown.svg");
			width: 17px;
			height: 14px;
			background-size: 17px 14px;
			display: inline-block;
			vertical-align: middle;
			color: $font-primary-color;
			margin-right: 15px;
			margin-top: -5px;
			transition: 300ms;
			background-repeat: no-repeat;
			background-position: center center;

			@media (--sm-lte) {
				background-size: 14px auto;
				margin-right: 5px;
			}
		}

		a:hover::before {
			transform: translate(-3px, 0);
		}
	}

	&__all {
		order: 2;
		margin: 0 50px;

		a {
			display: block;
			// text-indent: -9999px;
			width: 30px;
			height: 40px;
			background: url("../img/icon-back.png") no-repeat top;
			background-size: 30px;
			padding-top: 25px;
			font-family: $font-family-lato;
			font-weight: 600;
		}
	}

	&__next {
		order: 3;

		a::after {
			content: "";
			background-image: url("../img/icon-ar-right-brown.svg");
			width: 17px;
			height: 14px;
			background-size: 17px 14px;
			display: inline-block;
			vertical-align: middle;
			color: $font-primary-color;
			margin-left: 15px;
			margin-top: -5px;
			transition: 300ms;
			background-repeat: no-repeat;
			background-position: center center;

			@media (--sm-lte) {
				background-size: 14px auto;
				margin-left: 5px;
			}
		}

		a:hover::after {
			transform: translate(3px, 0);
		}
	}

	&__numbers {
		display: flex;
		font-weight: bold;
		list-style: none;
		margin: 0 30px;
		order: 2;
		padding: 0;
	}

	&__number {
		display: block;
		list-style: none;
		margin: 0 5px;

		@media (--sm-lte) {
			margin: 0 2px;
		}

		a {
			border-radius: 50%;
			width: 36px;
			height: 36px;
			text-align: center;
			line-height: 36px;
			font-size: 1.8rem;
			font-family: $font-family-lato;
			display: block;
			transition: 300ms;

			@media (--sm-lte) {
				width: 24px;
				height: 24px;
				font-size: 1.2rem;
				line-height: 24px;
			}

			&:hover {
				background: $pink-color;
				color: $lightest-color;
			}
		}

		// 🐲 baserCMSの仕様上 マルチクラスでしかカレントを定義できない
		&--current { // stylelint-disable-line selector-class-pattern
			a {
				background: $pink-color;
				color: $lightest-color;
			}

			&:hover { // stylelint-disable-line selector-class-pattern
				padding-bottom: 0;
			}
		}
	}
}
