.c-sidebar {
	width: 240px;
	margin-left: 60px;

	@media (--sm-lte) {
		width: 100%;
		margin: 0 0 30px;
		margin-left: 0;
	}

	ul {
		list-style: none;
	}

	&__archive-heading {
		font-size: 1.8rem;
		line-height: 2;
		margin: 0 0 20px;
		padding: 5px 35px 10px;
		position: relative;
		background: url("../img/bg-title-archive.png") repeat-x left bottom;
		font-weight: bold;
		font-weight: 600;

		@media (--sm-lte) {
			font-size: 1.6rem;
			padding: 5px 25px 10px;
			margin-bottom: 10px;
		}

		&::before {
			content: "";
			background: url("../img/icon-h4.svg") no-repeat;
			display: block;
			width: 21px;
			height: 21px;
			background-size: 21px 21px;
			position: absolute;
			left: 0;
			top: 10px;

			@media (--sm-lte) {
				width: 20px;
				height: 20px;
				background-size: 20px auto;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}


	&__archive {
		ul {
			padding: 0;
			margin: 0;
		}

		li {
			margin-bottom: 4px;
			a {
				color: $font-primary-color;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}

				@media (--sm-lte) {
					font-size: 1.4rem;
				}
			}

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background: $pink-color;
				border-color: transparent;
				width: 7px;
				height: 7px;
				margin-right: 8px;
			}
		}
	}

	&__recent {
		margin: 0 0 50px;

		@media (--sm-lte) {
			margin: 0 0 30px;
		}

		ul {
			padding: 0;
			margin: 0;
		}

		li {
			border-bottom: 1px dashed #ccc;
			padding-bottom: 10px;
			margin-bottom: 10px;

			a {
				color: $font-primary-color;
				text-decoration: none;
				transition: 300ms;
				font-size: 1.5rem;

				@media (--sm-lte) {
					font-size: 1.4rem;
				}

				time {
					display: block;
					font-family: $font-family-secondary;
					color: $primary-color;
					font-weight: 600;
					font-size: 1.5rem;

					@media (--sm-lte) {
						font-size: 1.4rem;
					}
				}

				&:hover {
					color: $primary-color;
				}
			}
		}
	}
}
