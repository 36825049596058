.c-card {
	font-size: 1.6rem;
	height: 100%;
	outline: 2px solid transparent;
	overflow: hidden;
	position: relative;
	transition: 300ms;
	border-radius: 8px;
	background: $lightest-color;

	> a {
		color: $font-primary-color;
		height: 100%;
		text-decoration: none;
		transition: 300ms;
		display: block;

		&:hover {
			opacity: .7;
		}
	}

	&__img {
		background: $gray-color02;
		flex: 0 1 auto;
		height: 0;
		overflow: hidden;
		padding-top: calc(9 / 16 * 100%);
		position: relative;
		width: 100%;

		img {
			display: block;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			@supports (object-fit: cover) {
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}

	&__img-news {
		background: $gray-color02;
		flex: 0 1 auto;
		height: 0;
		overflow: hidden;
		padding-top: calc(9 / 16 * 100%);
		position: relative;
		width: 100%;

		img {
			display: block;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			@supports (object-fit: cover) {
				height: 100%;
				object-fit: cover;
				object-position: center top;
			}
		}
	}

	&__header {
		flex: 0 1 auto;
		padding: 0 22px;
	}

	&__body {
		padding: 15px 30px;

		@media (--sm-lte) {
			padding: 15px;
		}
	}

	&__news-body {
		padding: 20px 0 0;

		@media (--sm-lte) {
			padding: 15px 0 0;
		}

		p {
			font-size: 1.4rem;
			margin: 8px 0 10px;
		}

		.c-card__time {
			font-size: 1.4rem;
		}
	}

	&__title {
		font-size: 1.5rem;
		font-weight: normal;
		margin: 0 auto;
		padding: 0;
		font-weight: 500;

		@media (--sm-lte) {
			font-size: 1.4rem;
		}
	}

	// ブログのみ
	&__meta {
		//
	}

	// ブログのみ
	&__time {
		color: $primary-color;
		font-weight: 600;
		letter-spacing: .02em;
		font-family: $font-family-secondary;
		display: block;
		font-size: 1.5rem;
		margin-bottom: 5px;

		@media (--sm-lte) {
			font-size: 1.4rem;
			margin-bottom: 0;
		}
	}

	// ブログのみ
	&__category {
		//
	}

	// ブログのみ
	&__tags {
		ul {
			list-style: none;
			margin: 0 auto;
			padding: 0;
		}
	}

	&__description {
		margin: 0;
		padding: 0;

		p {
			margin: 0;
			padding: 0;
		}
	}
}

.c-card-item {
	a {
		padding: 20px 20px 10px;
	}
}
