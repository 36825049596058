.c-page-sub {

	&__content-title {
		margin-bottom: 25px;

		@media (--sm-lte) {
			margin-bottom: 20px;
		}
	}

	&__breadcrumb {
		//
	}

	&__content {
		margin-bottom: 20px;
	}

	&__content-detail {
		width: 880px;
		margin: 0 auto;
		padding: 26px 0 50px;

		.c-page-sub-blog__content & {
			width: 100%;
			padding-bottom: 0;
			padding-top: 0;
		}

		@media (--sm-lte) {
			width: 100%;
			padding: 0 15px;
		}
	}
}

.c-page-wrapper {
	overflow: hidden;
	min-width: 1100px;

	@media (--sm-lte) {
		min-width: 320px;
	}
}

.c-blog-cd {
	padding: 50px 0 60px;
	text-align: center;
	background: url("../img/bg-home-lower.png") no-repeat center 100px;

	@media (--sm-lte) {
		padding: 35px 18px 10px;
		background-size: 90% auto;
		background-position: center 15px;
	}

	&__heading {
		margin: 0 0 10px;

		@media (--sm-lte) {
			img {
				width: 100%;
				max-width: 308px;
				height: auto;
			}
		}
	}

	&__bnr {
		@media (--sm-lte) {
			img {
				width: 100%;
				max-width: 348px;
			}
		}

		a {
			display: inline-block;
			transition: 300ms;

			&:hover {
				opacity: .8;
			}
		}
	}
}

.c-page-sub-blog {
	width: 1000px;
	margin: 0 auto;
	display: flex;
	justify-content: center;

	@media (--sm-lte) {
		display: block;
		width: 100%;
		padding: 0 15px;
	}

	.c-page-sub__content-detail {
		@media (--sm-lte) {
			padding: 0;
		}

		.c-content-main {
			h2 {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
				background-position: 5px bottom;
			}
		}
	}
}

.c-page-sub-news {
	width: 1000px;
	margin: 0 auto;

	@media (--sm-lte) {
		display: block;
		width: 100%;
		padding: 0 15px;
	}
}

.c-page-sub-blog__content {
	width: 700px;


	@media (--sm-lte) {
		width: 100%;
		margin: 0 0 30px;
	}

	&-inner {
		background: #fff;
		padding: 30px 40px 10px;
		border-radius: 10px;

		@media (--sm-lte) {
					padding: 20px 15px;
		}
	}
}

.c-blog-header {
	letter-spacing: .02em;
	background: url("../img/bg-title-archive.png") repeat-x left bottom;
	padding-bottom: 20px;
	margin-bottom: 30px;

	@media (--sm-lte) {
		padding-bottom: 10px;
		margin-bottom: 20px;
	}

	&__title {
		margin: 0;
		font-weight: bold;
		font-size: 2.2rem;
		margin-bottom: 5px;

		@media (--sm-lte) {
			font-size: 1.8rem;
			margin-bottom: 2px;
		}
	}

	&__time {
		display: block;
		font-family: $font-family-secondary;
		color: $primary-color;
		font-weight: 600;
		font-size: 1.5rem;

		@media (--sm-lte) {
			font-size: 1.2rem;
		}
	}
}
