.c-nav-sitemap {
	background: $secondary-color;
	padding: 10px 0 60px;

	@media (--sm-lte) {
		padding: 0 15px 60px;
		margin-top: -40px;
	}

	%list {
		margin: 0;
	}

	%item {
		display: block;
		margin: 0;
		padding: 0;
		letter-spacing: .02em;
	}

	a {
		color: $lightest-color;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&__body {
		margin: 0 auto;
		overflow: hidden;
		width: 1000px;
		display: flex;
		justify-content: space-between;

		@media (--sm-lte) {
			width: 100%;
			display: block;
		}

		> ul {
			margin: 0;
			padding: 0;
		}
	}

	li[data-sitemap-level="1"] {
		@extend %item;
		margin: 0 0 15px;

		@media (--sm-lte) {
			border-bottom: 1px dashed $lightest-color;
			margin: 0;
			padding: 9px 0;
		}

		a,
		button {
			text-align: left;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			padding: 0;
			appearance: none;
			font-size: 1.5rem;
			font-weight: bold;
			color: $lightest-color;
			letter-spacing: .02em;
			width: 100%;

			@media (--sm-lte) {
				display: block;
			}

			&::before {
				content: "";
				display: inline-block;
				border: 2px solid $yello-color;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				margin-right: 10px;
				vertical-align: middle;
				margin-top: -2px;
			}
		}
	}

	&__trigger {
		position: relative;

		span {
			&::after,
			&::before {
				content: "";
				display: block;
				width: 15px;
				height: 2px;
				background: $lightest-color;
				position: absolute;
				right: 3px;
				top: 50%;
				margin: -1px;
				transition: all 400ms;
			}

			&::before {
				transform: rotate(90deg);
			}
		}
	}

	&__trigger-active {
		span {
			&::after {
				transform: rotate(180deg);
			}

			&::before {
				transform: rotate(0deg);
			}
		}
	}

	ul[data-sitemap-level="2"] {
		@extend %list;
		padding: 0 0 0 1.45em;
		margin: 5px 0 20px;

		@media (--sm-lte) {
			margin: 10px 0 5px;
			display: none;
		}
	}

	li[data-sitemap-level="2"] {
		@extend %item;
		margin: 0 0 .4em;

		a {
			font-weight: 300;

			@media (--sm-lte) {
				font-size: 1.3rem;
			}

			&::before {
				content: "";
				width: 7px;
				height: 2px;
				background: $lightest-color;
				border: 0;
				margin-right: 7px;
				border-radius: 50%;
			}
		}
	}

	&__lower {
		@media (--sm-lte) {
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;

				small {
					@media (--sm-lte) {
						display: inline-block;
					}
				}
			}
		}
	}

	&__lower-last {
		@media (--sm-lte) {
			border-bottom: 0 !important;
		}
	}
}
