@keyframes swing {
	0% {transform:translate(0, 0);}
	50% {transform:translate(0, -8px);}
	100% {transform:translate(0, 0);}
}

@keyframes swing-r {
	0% {transform:translate(0, 0);}
	50% {transform:translate(0, 8px);}
	100% {transform:translate(0, 0);}
}


.c-page-home-lead {
	position: relative;
	margin: 0 0 90px;

	@media (--sm-lte) {
		margin: 0 0 80px;
	}

	&__inner {
		width: $page-base-width;
		margin: -40px auto 0;
		position: relative;
		z-index: 2;

		@media (--sm-lte) {
			width: 100%;
			padding: 0 25px;
			margin-top: -20px;
		}

		&::before {
			content: "";
			background: url("../img/bg-home-easing-circle-01.png") no-repeat;
			display: block;
			width: 459px;
			height: 443px;
			position: absolute;
			right: -225px;
			top: 90px;
			opacity: 0;
			transition: 1200ms $easeOutQuart;
			transition-delay: 800ms;
			animation-name: swing-r;
			animation-duration:2s;
			animation-iteration-count:infinite;
			animation-timing-function:ease;

			@media (--sm-lte) {
				width: 136px;
				height: 140px;
				background-size: 100% auto;
				right: -5px;
				top: auto;
				bottom: -160px;
			}


			.c-page-home-inview__active & {
				transform: translateY(0px);
				top: 10px;
				opacity: 1;

				@media (--sm-lte) {
					top: auto;
					bottom: -100px;
				}
			}
		}
	}

	&__title {
		margin: 0 0 20px;

		@media (--sm-lte) {
			margin: 0;

			img {
				width: 100%;
				max-width: 350px;
			}
		}
	}

	&__low {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@media (--sm-lte) {
			display: block;
		}

		p {
			line-height: 1.8;
			margin: 0 0 20px;

			@media (--sm-lte) {
				font-size: 1.2rem;
			}
		}
	}

	&__button {

		@media (--sm-lte) {
			text-align: center;
		}

		a {
			background: $secondary-color;
			color: $lightest-color;
			font-size: 1.6rem;
			font-weight: 500;
			text-decoration: none;
			padding: 12px 10px;
			display: inline-block;
			width: 220px;
			text-align: center;
			letter-spacing: .04em;
			border-radius: 40px;
			position: relative;
			transition: 300ms;

			&::after {
				content: "";
				display: block;
				background: url("../img/icon-ar.svg") no-repeat;
				width: 17px;
				height: 14px;
				background-size: 17px auto;
				position: absolute;
				transform: translate(0, -50%);
				right: 20px;
				top: 50%;
				transition: 300ms;
			}

			&:hover {
				background-color: $primary-color;

				&::after {
					right: 17px;
				}
			}
		}
	}
}

.c-page-home-goal {
	margin: 0 0 80px;

	@media (--sm-lte) {
		padding: 0 18px;
		margin: 0 0 60px;
	}

	&__heading {
		@media (--sm-lte) {
			img {
				width: 100%;
				max-width: 300px;
			}
		}
	}

	&__inner {
		width: $page-base-width;
		position: relative;
		margin: 0 auto;
		text-align: center;
		padding: 45px 40px 55px;
		background: #fff;
		border-radius: 20px;
		box-shadow: 1.5px 2.598px 0px 0px rgba(69, 60, 35, 0.11);

		@media (--sm-lte) {
			width: 100%;
			padding: 30px 20px 20px;
			border-radius: 10px;
		}

		&::before {
			content: "";
			background: url("../img/icon-home-goal.svg") no-repeat center top;
			display: block;
			width: 100%;
			height: 54px;
			background-size: 86px auto;
			position: absolute;
			top: -54px;
			left: 0;

			@media (--sm-lte) {
				height: 40px;
				background-size: auto 40px;
				top: -40px;
			}
		}

		&::after {
			content: "";
			background: url("../img/bg-home-easing-circle-02.png") no-repeat;
			display: block;
			width: 240px;
			height: 168px;
			position: absolute;
			left: -180px;
			bottom: -70px;
			opacity: 0;
			transition: 1200ms $easeOutQuart;
			transition-delay: 800ms;
			animation-name: swing;
			animation-duration:2s;
			animation-iteration-count:infinite;
			animation-timing-function:ease;

			@media (--sm-lte) {
				bottom: -170px;
				width: 171px;
				height: 130px;
				background-size: 171px auto;
				left: -10px;
				background-size: 100px auto;
			}


			.c-page-home-inview__active & {
				bottom: 10px;
				opacity: 1;

				@media (--sm-lte) {
					bottom: -100px;
				}
			}
		}
	}

	&__body {
		display: flex;
		justify-content: space-between;
		list-style: none;
		padding: 0;
		margin: -20px 0 0;
		background: url("../img/bg-home-goal-line.png") no-repeat center 80px;

		@media (--sm-lte) {
			display: block;
			text-align: center;
			margin-top: 0;
			background: url("../img/bg-home-goal-line-xs.png") no-repeat;
			background-position: calc(50% - 10px) 40%;
			background-size: 85% auto;
		}
	}

	&__heading {
		margin: 0;

		@media (--sm-lte) {
			margin: 0 0 20px;
		}
	}

	&__element {
		@media (--sm-lte) {
			margin: 0 0 20px;
		}
	}

	&__text {
		font-weight: 500;
		font-size: 2rem;
		border-bottom: 1px dashed $font-primary-color;
		display: inline-block;
		letter-spacing: .04em;

		@media (--sm-lte) {
			font-size: 1.5rem;
		}
	}

	&__ph {
		border: 6px solid;
		border-radius: 50%;
		overflow: hidden;
		position: relative;

		@media (--sm-lte) {
			display: inline-block;
			border-width: 3px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
	}

	&__num {
		margin: -28px 0 8px;
		position: relative;

		@media (--sm-lte) {
			margin-top: -30px;
		}
	}

	&__ph-01 {
		border-color: $pink-color;
		width: 285px;
		height: 285px;

		@media (--sm-lte) {
			width: 85%;
			height: 0;
			padding-top: 85%;

		}
	}

	&__ph-02 {
		border-color: $secondary-color;
		width: 240px;
		height: 240px;
		margin-top: 75px;

		@media (--sm-lte) {
			height: 0;
			width: 70%;
			padding-top: 70%;
			margin-top: 10px;
		}
	}

	&__ph-03 {
		border-color: $primary-color;
		width: 285px;
		height: 285px;

		@media (--sm-lte) {
			width: 85%;
			height: 0;
			padding-top: 85%;
		}
	}
}

.c-page-home-news {
	background: $lightest-color;
	padding: 65px 0 40px;

	@media (--sm-lte) {
		padding: 35px 18px 45px;
	}

	&__heading {
		text-align: center;
		margin: 0 0 40px;
		padding-top: 48px;
		background: url("../img/icon-home-news.svg") no-repeat center top;
		background-size: 63px 32px;

		@media (--sm-lte) {
			background-size: 55px auto;
			padding-top: 38px;

			img {
				width: 110px;
			}
		}
	}

	&__inner {
		width: $page-base-width;
		margin: 0 auto;

		@media (--sm-lte) {
			width: 100%;
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -20px;

		@media (--sm-lte) {
			display: block;
			margin: 0;
		}
	}

	&__ph {
		width: 170px;
		height: 120px;
		border-radius: 10px;
		overflow: hidden;
		position: relative;

		@media (--sm-lte) {
			width: 110px;
			height: 78px;
			border-radius: 6px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
	}

	&__contents {
		width: calc(100% - 170px);
		padding-left: 30px;

		@media (--sm-lte) {
			padding-left: 15px;
			width: calc(100% - 110px);
		}

		time {
			color: $primary-color;
			font-weight: 600;
			letter-spacing: .02em;
			font-family: $font-family-secondary;
			display: block;

			 @media (--sm-lte) {
				 font-size: 1.3rem;
				 margin-bottom: 3px;
			 }
		}
	}

	&__title {
		font-size: 1.5rem;

		@media (--sm-lte) {
			font-size: 1.2rem;
		}
	}

	&__element {
		width: calc(50% - 40px);
		margin: 0 20px 25px;
		padding-bottom: 25px;

		&:nth-child(1),
		&:nth-child(2) {
			border-bottom: 1px dashed $gray-color03;
		}

		@media (--sm-lte) {
			border-bottom: 1px dashed $gray-color03;
			width: 100%;
			margin: 0 0 20px;
			padding-bottom: 20px;

			&:last-child {
				border-bottom: 0;
			}
		}

		a {
			display: flex;
			text-decoration: none;
			align-items: center;
			transition: 300ms;
			color: $font-primary-color;

			@media (--sm-lte) {
				align-items: flex-start;
			}

			&:hover {
				color: $primary-color;
			}
		}
	}

	&__button {
		text-align: center;
		width: 100%;

		a {
			background: $secondary-color;
			color: $lightest-color;
			font-size: 1.6rem;
			font-weight: 500;
			text-decoration: none;
			padding: 12px 10px;
			display: inline-block;
			width: 220px;
			text-align: center;
			letter-spacing: .04em;
			border-radius: 40px;
			position: relative;
			transition: 300ms;

			&::after {
				content: "";
				display: block;
				background: url("../img/icon-ar.svg") no-repeat;
				width: 17px;
				height: 14px;
				background-size: 17px auto;
				position: absolute;
				transform: translate(0, -50%);
				right: 20px;
				top: 50%;
				transition: 300ms;
			}

			&:hover {
				background-color: $primary-color;

				&::after {
					right: 17px;
				}
			}
		}
	}
}

.c-page-home-nav {
	background: url("../img/bg-home-nav.jpg") no-repeat center center;
	background-size: cover;
	padding: 120px 0 70px;

	@media (--sm-lte) {
		padding: 60px 18px 1px;
		background-image: url("../img/bg-home-nav-xs.jpg");
	}

	&__inner {
		width: $page-base-width;
		margin: 0 auto;

		@media (--sm-lte) {
			width: 100%;
		}
	}

	&__body {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		text-align: center;

		@media (--sm-lte) {
			flex-wrap: wrap;
		}
	}

	&__heading {
		background: $lightest-color;
		border-right: 1px dashed $gray-color03;
		padding: 45px 0 25px;
		transition: 400ms;

		a:hover & {
			background: #eefdff;
		}

		img {
			height: 100%;

			@media (--sm-lte) {
				height: 38px;
				width: auto;
			}
		}
	}

	&__image {
		img {
			border-right: 1px dashed $gray-color03;

			@media (--sm-lte) {
				width: 100%;
			}
		}
	}

	&__element {
		&:first-child {
			border-radius: 0 0 5px 0;
		}
		&:last-child {
			border-radius: 0 5px 5px 0;
		}

		@media (--sm-lte) {
			width: 50%;
			margin: 0 0 40px;
		}

		a {
			display: block;
			position: relative;

			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 80px;
				background-size: 73px auto;
				position: absolute;
				background-repeat: no-repeat;
				background-position: center top;
				top: -40px;
				transition: 400ms $easeInOutBack;

				@media (--sm-lte) {
					background-size: 50px auto;
					height: 55px;
					top: -25px;
				}

			}

			&:hover::before {
				transform: translate(0, -10px);
			}
		}

		&-characteristic a::before {
			background-image: url("../img/icon-home-characteristic.svg");
		}

		&-facility a::before {
			background-image: url("../img/icon-home-facility.svg");
		}

		&-1day a::before {
			background-image: url("../img/icon-home-1day.svg");
		}

		&-year a::before {
			background-image: url("../img/icon-home-year.svg");
		}
	}

	&__element:last-child &__heading {
		border-radius: 0 5px 0 0;
		border-right: 0;
	}

	&__element:first-child &__heading {
		border-radius: 5px 0 0 0;
	}

	&__element:nth-child(odd) &__heading {
		@media (--sm-lte) {
			border-radius: 5px 0 0 0;
		}
	}

	&__element:nth-child(even) &__heading {
		@media (--sm-lte) {
			border-radius: 0 5px 0 0;
			border-right: none;
		}
	}

	&__element:last-child &__image img {
		border-right: 0;
	}

	&__element:nth-child(even) &__image img {
		@media (--sm-lte) {
			border-right: none;
		}
	}

	&__element:nth-child(even) &__image {
		@media (--sm-lte) {
			overflow: hidden;
			border-radius: 0 0 5px 0;
		}
	}
}
