.c-list-category {
	font-size: 1.2rem;

	@media (--xs) {
		display: none;
	}

	ul {
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0 auto 50px;
		padding: 0;
	}

	li {
		list-style: none;
		margin: 0 10px;
		padding: 0;
	}

	a {
		background: $gray-color02;
		color: $darkest-color;
		font-weight: bold;
		border: 1px solid transparent;
		border-radius: 5px;
		display: block;
		padding: 1em 1.9em;
		text-decoration: none;
	}
}
