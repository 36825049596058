.c-nav-global {
	position: relative;
	margin-top: 45px;

	@media (--sm-lte) {
		margin: 0;
	}

	&__body {
		margin: 0 auto;

		@media (--md-lte) {
			padding: 0;
		}
	}

	&__list {
		align-items: stretch;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: 0;
		padding: 0;

		@media (--sm-lte) {
			justify-content: center;
			flex-wrap: wrap;
		}
	}

	&__item {
		display: block;
		flex: 1 1 auto;
		margin-left: 55px;

		@media (--sm-lte) {
			width: 50%;
			border-bottom: 1px dotted $gray-color02;
			margin: 10px 0;
			position: relative;

			&:last-child {
				width: 100%;
				margin-bottom: 0;
			}

			&:nth-child(even) {
				&::after {
					content: "";
					height: calc(100% - 10px);
					width: 1px;
					border-left: 1px dotted $gray-color02;
					position: absolute;
					top: 0;
				}
			}
		}

		> a {
			display: block;
			text-align: center;
			text-decoration: none;
			color: $font-primary-color;
			font-weight: 500;
			font-size: 1.6rem;
			transition: 200ms linear;
			padding: 38px 5px 0;
			background-repeat: no-repeat;
			position: relative;
			min-width: 90px;

			&::after {
				position: absolute;
				bottom: -17px;
				left: 0;
				content: '';
				width: 100%;
				height: 5px;
				background: $primary-color;
				transform: scale(0, 1);
				transform-origin: right top;
				transition: transform 400ms;

				@media (--sm-lte) {
					content: none;
				}
			}

			@media (--sm-lte) {
				padding: 38px 5px 10px;
				font-size: 1.8rem;
				min-width: 0;
			}

			> span {
				display: block;
				position: relative;

				@media (--md-lte) {
					border-left: none;
				}

				small {
					font-family: $font-family-lato;
					display: block;
					font-size: 1.1rem;

					@media (--sm-lte) {
						font-size: 1.2rem;
					}
				}
			}

			&:hover::after {
				transform-origin: left top;
				transform: scale(1, 1);
			}
		}
	}

	&__item-about {
		background-image: url("../img/icon-nav-about.svg");
		background-size: 48px 35px;
		background-position: center top;
	}

	&__item-news {
		background-image: url("../img/icon-nav-news.svg");
		background-size: 56px 28px;
		background-position: center 2px;
	}

	&__item-care {
		background-image: url("../img/icon-nav-care.svg");
		background-size: 42px 24px;
		background-position: center 6px;
	}

	&__item-information {
		background-image: url("../img/icon-nav-information.svg");
		background-size: 53px 25px;
		background-position: center 6px;
	}

	&__item-blog {
		background-image: url("../img/icon-nav-blog.svg");
		background-size: 54px 28px;
		background-position: center 6px;
	}

	&__sub-nav {
		position: absolute;
		left: 0;
		width: 100%;
		background: $secondary-color;

		@media (--md-lte) {
			position: static;
		}

		> ul {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-around;

			@media (--md-lte) {
				display: block;
				padding: 0;
			}

			> li {
				list-style: none;

				@media (--md-lte) {
					border-bottom: 1px solid $border-primary-color;
				}

				> a {
					color: $lightest-color;
					text-decoration: none;
					padding: 15px;
					width: 100%;
					display: block;

					@media (--md-lte) {
						text-align: center;
						padding: 10px 4%;
					}

					&:hover {
						opacity: .7;
					}
				}
			}
		}
	}
}
