.c-header {
	font-size: 1.6rem;
	border-top: 4px solid $secondary-color;

	@media (--sm-lte) {
		border-width: 2px;
	}

	&__body {
		width: $page-base-width;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		position: relative;
		padding: 20px 0 15px;

		@media (--sm-lte) {
			display: block;
			width: 100%;
			padding: 15px 0;
		}
	}

	&__block {
		@media (--sm-lte) {
			position: absolute;
			left: 0;
			top: 110px;
			z-index: 100;
			width: 100%;
			background: $bg-body-color;
			border-top: 2px solid $secondary-color;
			border-bottom: 2px solid $secondary-color;
			padding: 10px 15px;
			display: none;
		}
	}

	&__title {
		flex: 0 1 auto;
	}

	&__site-name {
		margin: 0;

		@media (--sm-lte) {
			margin: 0 auto;
			text-align: center;
		}

		> a {
			display: block;
			text-decoration: none;
			text-indent: -9999px;
			background: url("../img/logo.png") no-repeat;
			width: 169px;
			height: 132px;
			background-size: 169px auto;

			@media (--sm-lte) {
				width: 105px;
				height: 82px;
				background-size: 105px auto;
				margin: 0 auto;
			}
		}
	}

	&__description {
		margin: 0;
	}

	&__info {
		display: flex;
		flex: 0 1 auto;
		flex-direction: column;
	}

	&__links {
		display: flex;
		flex: 0 1 auto;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		top: 20px;

		@media (--sm-lte) {
			position: static;
			display: block;
		}

		> li {
			display: block;
			flex: 0 1 auto;
			margin: 0 0 0 20px;
			letter-spacing: .03em;

			@media (--sm-lte) {
				border-bottom: 1px dotted $gray-color02;
				position: relative;
				margin-left: 0;

				&:last-child {
					border-bottom: 0;
				}
			}

			&::before {
				content: "";
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: $pink-color;
				margin-right: 5px;

				@media (--sm-lte) {
					position: absolute;
					left: 3px;
					top: 50%;
					margin-top: -5px;
				}
			}

			> a {
				text-decoration: none;
				font-size: 1.4rem;
				color: $brown-color;

				@media (--sm-lte) {
					display: block;
					font-size: 1.8rem;
					padding: 15px 5px 15px 20px;
				}

				&:hover {
					color: $secondary-color-lighter2;
				}
			}
		}
	}

	&__nav-btn {
		position: absolute;
		right: 20px;
		top: 35px;
	}

	&__nav-btn-element {
		background-color: transparent;
		cursor: pointer;
		outline: none;
		appearance: none;
		color: $font-primary-color;
		border: 0;
		position: relative;
		display: block;
		width: 40px;
		font-family: $font-family-secondary;
		font-weight: 500;
		font-size: 1rem;
		padding: 0;
		height: 48px;
		background-size: 38px auto;
		transition: 500ms;

		&::after {
			content: "MENU";
			font-family: $font-family-secondary;
			font-size: 1.3rem;
			transform: scale(.9);
			position: absolute;
			bottom: 2px;
			width: 100%;
			display: block;
			transition: 500ms;
		}

		span {
			background: $primary-color;
			box-sizing: border-box;
			display: inline-block;
			height: 4px;
			left: 1px;
			position: absolute;
			transition: all 400ms;
			width: 40px;
			border-radius: 20px;

			&:nth-of-type(1) {
				top: 10px;
			}

			&:nth-of-type(2) {
				top: 20px;
			}

			&:nth-of-type(3) {
				top: 0px;
			}
		}
	}

	&__nav-btn-element-active {

		span:nth-of-type(1) {
			transform: translateY(-2px) rotate(-45deg);
		}

		span:nth-of-type(2) {
			right: -60%;
			opacity: 0;
		}

		span:nth-of-type(3) {
			transform: translateY(8px) rotate(45deg);
		}
	}
}

// stylelint-disable
// 🐲 baserCMS用管理ツールバー
//
#ToolBar {
	opacity: 0.2;
	transition: 300ms;
	&:hover {
		opacity: 1;
	}
}
// stylelint-enable
